import { h } from 'hyperapp'
import { Link } from '@hyperapp/router'
import { getFilterPath, setPosition, sortItems } from '../../modules/utils'

export default ({ field, name, order, filter, items, route, format, valueField, displayField, orderField, setOrderAction, filterAction }) => {
  if (!name) name = field.charAt(0).toUpperCase() + field.slice(1);

  const canOrder = field && typeof order !== 'undefined';
  const canFilter = field && typeof filter !== 'undefined';

  return (
    <th>
      <div class="field is-horizontal">
        <div class="field-label">
          <label class="label">{name}</label>
        </div>
        <div class="field-body">
          <div class="field is-grouped">
            {canOrder ? <div class="control"><ThOrder field={field} order={order} setOrderAction={setOrderAction} route={route} /></div> : null}
            {canFilter ? <div class="control"><ThFilter field={field} filter={filter} items={items} filterAction={filterAction} route={route} format={format} valueField={valueField} displayField={displayField} orderField={orderField} /></div> : null}
          </div>
        </div>
      </div>
    </th>
  )
}

const ThOrder = ({ field, order, setOrderAction, route }) => {
  if (!field) return null;

  const assignOrder = (e) => {
    let suffix = '';
    let nextSuffix = '_asc';
    if (order === field) {
      suffix = `_asc`;
      nextSuffix = '_desc';
    } else if (order === `-${field}`) {
      suffix = `_desc`;
      nextSuffix = '';
    }

    const radio = document.getElementById(`thord_${field}${suffix}`);
    radio.checked = true;
    e.querySelectorAll('label.radio').forEach(l => l.classList.add('is-hidden'));
    const nextOpt = document.getElementById(`thord_${field}${nextSuffix}`);
    nextOpt.nextSibling.classList.remove('is-hidden');
  }
  const getPath = (orderBy) => {
    const pathname = window.location.pathname;
    const search = window.location.search || "";
    const params = new URLSearchParams(search);
    if (orderBy)
      params.set('order', `${orderBy}`);
    else
      params.delete('order');
    const str = params.toString();
    return pathname + (str.length ? `?${str}` : '');
  }
  const setOrder = (o) => {
    let orderBy = '';
    if (o > 0) orderBy = field;
    else if (o < 0) orderBy = `-${field}`;
    route.go(getPath(orderBy));
    setOrderAction(orderBy);
  }

  return (<div class="field is-narrow" oncreate={assignOrder} onupdate={assignOrder}>
    <div class="control">
      <input type="radio" id={`thord_${field}`} name={`thord_${field}`} onchange={() => setOrder(0)} />
      <label class="radio is-hidden" for={`thord_${field}`}>
        <span class="icon">
          <i class="fas fa-sort-up"></i>
        </span>
      </label>
      <input type="radio" id={`thord_${field}_asc`} name={`thord_${field}`} onchange={() => setOrder(1)} />
      <label class="radio is-hidden" for={`thord_${field}_asc`}>
        <span class="icon">
          <i class="fas fa-sort"></i>
        </span>
      </label>
      <input type="radio" id={`thord_${field}_desc`} name={`thord_${field}`} onchange={() => setOrder(-1)} />
      <label class="radio is-hidden" for={`thord_${field}_desc`}>
        <span class="icon">
          <i class="fas fa-sort-down"></i>
        </span>
      </label>
    </div>
  </div>)
}

const ThFilter = ({ field, items, filter, route, format, filterAction, valueField, displayField, orderField }) => {
  if (!field) return null;

  const setFilter = (f) => {
    route.go(getFilterPath(field, f));
    filterAction({ [field]: f });
  }

  const close = (e) => {
    document.querySelectorAll('.dropdown.is-active').forEach(d => {
      if (!d.contains(e.target)) {
        d.classList.remove('is-active')
      }
    })
  }

  const oncreate = (e) => {
    if (filter[field]) {
      const selected = e.querySelector(`.dropdown-item[data-value="${filter[field]}"]`);
      if (selected) {
        setActive(selected)
        const btn = e.querySelector(`a.button`);
        btn.classList.add('is-primary');
      }
    }
    document.addEventListener('click', close)
  }

  const onupdate = (e) => {
    const btn = e.querySelector(`a.button`);
    const items = e.querySelectorAll('.dropdown-item');
    btn.classList.remove('is-primary');
    if (items) {
      items.forEach(i => {
        i.classList.remove('is-active')
      })
    }
    if (filter[field]) {
      const selected = e.querySelector(`.dropdown-item[data-value="${filter[field]}"]`);
      if (selected) {
        setActive(selected);
        btn.classList.add('is-primary');
      }
    }
  }

  const ondestroy = () => {
    document.removeEventListener('click', close)
  }

  const toggle = (e) => {
    const dropdown = e.currentTarget.closest('.dropdown')
    dropdown.classList.toggle('is-active')
    if (dropdown.classList.contains('is-active')) {
      const dm = dropdown.querySelector('.dropdown-menu');
      // setPosition(dm)
      const input = dropdown.querySelector(".search-container input")
      if (input) {
        input.focus()
      }
    }
  }

  const setActive = (selected) => {
    selected.parentElement.childNodes.forEach(c => c.classList.remove('is-active'))
    selected.classList.add('is-active')
    const value = selected.dataset.value || selected.innerText
    return value
  }

  const select = (e) => {
    const selected = e.currentTarget
    const value = setActive(selected)
    setFilter(value)
    toggle(e)
  }

  const clear = (e) => {
    e.currentTarget.parentElement.childNodes.forEach(c => c.classList.remove('is-active'));
    setFilter()
    toggle(e)
  }

  return (<div class="field is-narrow">
    <div class="control">
      <div class="dropdown" oncreate={oncreate} ondestroy={ondestroy} onupdate={onupdate}>
        <div class="dropdown-trigger">
          <a class="button is-small" onclick={toggle}>
            <span class="icon is-small">
              <i class="fas fa-filter"></i>
            </span>
          </a>
        </div>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          {filter && <ThFilterContent items={items} select={select} clear={clear} setFilter={setFilter} format={format} value={filter[field]} valueField={valueField} displayField={displayField} orderField={orderField} />}
        </div>
      </div>
    </div>
  </div>)
}

const ThFilterContent = ({ items, setFilter, select, clear, format, value, valueField, displayField, orderField }) => {
  var timeout;
  const search = (e) => {
    if (timeout) clearTimeout(timeout);
    const value = e.currentTarget.value
    timeout = setTimeout(() => {
      setFilter(value)
    }, 1000)
  }
  const ondestroy = () => {
    if (timeout) clearTimeout(timeout);
  }
  if (!items || !items.length) {
    return (<div class="dropdown-content" ondestroy={ondestroy}>
      <span class="dropdown-item">
        <span class="search-container">
          <input class="input" type="text" placeholder="Search..." onkeyup={search} value={value} />
          <a class="rm_btn delete is-small" onclick={clear}></a>
        </span>
      </span>
    </div>)
  }
  return (<div class="dropdown-content">
    <a class="dropdown-item" onclick={clear}>
      None
      </a>
    {sortItems(items).map(e => {
      var v = e;
      var l = e;
      if (typeof e == 'object') {
        if (valueField) {
          v = e[valueField];
        }
        if (displayField) {
          l = e[displayField];
        }
      }
      if (format) {
        l = format(l);
      }
      return (
        <a key={v} class="dropdown-item" onclick={select} data-value={v}>{l}</a>
      )
    })}
  </div>)
}
