import { h } from 'hyperapp';
import { Parameter } from '../../modules/rule';
import { Dropdown, Buttons, Fields, TagsInput, StringField, RequiredField } from './common'
import { getParamValues, slugify } from '../../modules/utils'
import { ParamSelector } from './param-selector';

const generateAlias = (action) => {
  var alias = action.method;
  (action.parameters || []).forEach(p => {
    if (p && p.value) {
      var param = p.value;
      if (typeof p.value == 'object') {
        param = JSON.stringify(p.value)
      }
      alias += ('_' + param.toLowerCase());
    }
  })
  return slugify(alias);
}

export const Actions = ({ state, actions, hasUnmet }) => {
  const event = state.events.find(e => e.id == state.event);
  const paramValues = getParamValues(event, state);

  const toggle = () => {
    actions.setState({ showUnmetActions: !state.showUnmetActions });
  }

  return (<div>
    {hasUnmet && (<div class="tabs is-boxed">
      <ul>
        <li class={!state.showUnmetActions ? "is-active" : ""}><a onclick={toggle}>ON Met</a></li>
        <li class={state.showUnmetActions ? "is-active" : ""}><a onclick={toggle}>ON Unmet</a></li>
      </ul>
    </div>)}
    {(!hasUnmet || !state.showUnmetActions) ? (<ActionsList
      state={state}
      actions={state.actions}
      paramValues={paramValues}
      setAction={actions.setAction}
      removeAction={actions.removeAction}
      addAction={actions.addAction}
      getActionData={actions.getActionData}
      showEventFields={actions.showEventFields}></ActionsList>) :
      (<ActionsList
        state={state}
        actions={state.unmetActions}
        paramValues={paramValues}
        setAction={actions.setUnmetAction}
        removeAction={actions.removeUnmetAction}
        addAction={actions.addUnmetAction}
        getActionData={actions.getActionData}
        showEventFields={actions.showEventFields}></ActionsList>)}
  </div>)

}
const ActionsList = ({ state, actions, paramValues, setAction, removeAction, addAction, getActionData, showEventFields }) => {
  const showButton = !actions.length || actions[actions.length - 1].method;

  return (<div class="field">
    <div class="control">
      <ul class="action_list">
        {actions.map((action, idx) => (<Action action={action} event={state.event} criterias={state.criterias} datasources={state.datasources} onchange={setAction} onremove={removeAction} actionMethods={state.actionMethods} fetchData={getActionData} actionData={state.actionData} events={state.events} showEventFields={showEventFields} paramValues={paramValues} index={idx} />))}
      </ul>
    </div>
    {showButton && (<Buttons addTitle="Add action" onAddClick={addAction} addIcon="fa-angle-right" />)}
  </div>)
}

const Action = ({ action, event, onchange, onremove, actionData, fetchData, events, actionMethods, showEventFields, paramValues, index }) => {

  const actionMethod = action.method && (actionMethods || []).find(m => m.name == action.method);
  const hasAllParams = actionMethod && (!actionMethod.params || !actionMethod.params.length || action.parameters.length == actionMethod.params.length && !!action.parameters[action.parameters.length - 1].value);

  const setAction = (value) => {
    action.method = value;
    action.parameters = [];
    if (value) {
      const method = (actionMethods || []).find(m => m.name == value);
      action.parameters = method && method.params && method.params.map(p => {
        const paramType = method.data && method.data[p.type] && method.data[p.type].type || p.type;
        return new Parameter({ value: '', type: paramType });
      });
      if (!action.changed) {
        action.alias = generateAlias(action)
      }
    }
    onchange(action);
  }

  const setParam = (value, type, index, isDynamic) => {
    action.parameters[index] = new Parameter({ value, type, isDynamic });
    // if (actionMethod.params[index].additional) {
    //   const depIdx = actionMethod.params.findIndex(p => (p.id == actionMethod.params[index].additional));
    //   if (depIdx > -1) {
    //     let res = '';
    //     const triggerEvent = events.find(e => (e.id == event));
    //     const currentEvent = events.find(e => (e.id == value));
    //     if (currentEvent && triggerEvent && triggerEvent.fields.length && currentEvent.fields.length) {
    //       triggerEvent.fields.forEach(tf => {
    //         const field = currentEvent.fields.find(f => (f.id == tf.id));
    //         if (!!field) {
    //           if (res) res += ',';
    //           res += field.id;
    //         }
    //       });
    //     }
    //     action.parameters[depIdx] = new Parameter({ value: res, type: actionMethod.params[depIdx].type });
    //   }
    // }
    if (!action.changed) {
      action.alias = generateAlias(action)
    }
    onchange(action);
  }

  const setAlias = (alias) => {
    action.alias = alias;
    action.changed = true;
    onchange(action);
  }

  const validActions = (actionMethods || []).filter(a => {
    if (a.requirements) {
      const triggerEvent = events.find(e => (e.id == event));
      for (var i = 0, j = a.requirements.length; i < j; i++) {
        const r = a.requirements[i];
        const f = triggerEvent.fields.find(f => (f.id == r.id && f.type == r.type));
        if (!f) return false;
      }
    }
    return true
  });

  if (hasAllParams && !action.alias) {
    setAlias(generateAlias(action))
  }

  return (
    <li class="field is-grouped is-grouped-multiline" key={`${action.name}_${index}`}>
      <div class="control">
        <Dropdown text="--- select an action ---" items={validActions} valueField="name" displayField="desc" onchange={val => setAction(val)} value={action.method} />
      </div>
      {actionMethod && actionMethod.params && actionMethod.params.map((p, i) => {
        let classNames = "control";
        if ((!actionMethod.data || !actionMethod.data[p.type]) && p.type != 'event') {
          classNames += " param_field";
        }
        return (<div class={classNames} key={`${action.name}_p${i}`}>
          <ActionParam action={action} event={event} method={actionMethod} data={actionData} param={p} index={i} setParam={setParam} fetchData={fetchData} events={events} showEventFields={showEventFields} paramValues={paramValues} />
        </div>)
      })}
      {actionMethod && actionMethod.requirements && actionMethod.requirements.map((r, i) => {
        return (<div class="control" key={`${action.name}_r${i}`}>
          <StringField value={r.label} disabled={true} />
        </div>)
      })}
      {hasAllParams && (
        <div class="control action_alias param_field">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">as</label>
            </div>
            <div class="field-body">
              <RequiredField placeholder="Action Alias" onchange={({ target: { value } }) => setAlias(value)} value={action.alias} />
            </div>
          </div>
        </div>
      )}
      <div class="control">
        <button class="rm_btn delete" title="Remove reaction" onclick={() => onremove(action)}></button>
      </div>
    </li>
  )
}

const ActionParam = ({ action, event, data, method, param, index, setParam, fetchData, events, showEventFields, paramValues }) => {
  const paramValue = action.parameters[index] && action.parameters[index].value || '';
  const isDynamic = action.parameters[index] && action.parameters[index].isDynamic;
  if (param.type == 'event') {
    return (<Dropdown text="--- select an event ---" items={events} valueField="id" onchange={value => setParam(value, param.type, index)} value={paramValue} />)
  }
  if (param.type == 'array' && param.itemType) {
    return (<TagsInput type={param.itemType} value={paramValue} onchange={(value) => setParam(value, param.itemType, index)} placeholder={`Enter ${param.itemType}`} required={!param.optional} />)
  }
  if (param.type == 'eventFields') {
    const parentIdx = method.params.findIndex(p => (p.additional == param.id))
    if (parentIdx != -1 && action.parameters[parentIdx] && action.parameters[parentIdx].value) {
      return (<button class="button" title="Set event fields" onclick={() => showEventFields({ action, index, value: action.parameters[parentIdx].value })}>
        <span class="icon is-small"><i class="fas fa-plus"></i></span>
      </button>)
    }
    return null
  }
  if (param.type == 'capsuleProps') {
    var items, displayField, valueField;
    const parentIdx = method.params.findIndex(p => (p.additional == param.id))
    if (parentIdx != -1) {
      if (action.parameters[parentIdx] && action.parameters[parentIdx].value) {
        const { source, kind, key } = method.data[method.params[parentIdx].type];
        const path = `${source}/${kind}` + (!!key ? `/${key}` : '');
        const capsulesData = data && data[path] || null;
        if (!!capsulesData && !capsulesData.loading && !!capsulesData.data) {
          const capsuleData = capsulesData.data.find(c => c.component == action.parameters[parentIdx].value);
          if (capsuleData) {
            items = capsuleData.defaultProps;
            valueField = 'property';
          }
        }
      }
    } else if (event && (event.endsWith('_LOAD') || event.endsWith('_SUCCESS'))) {
      const ev = events.find(e => e.id == event.replace('_SUCCESS', '_LOAD'));
      if (ev) {
        items = ev.fields;
        valueField = 'id';
        displayField = 'label';
      }
    }
    if (items) {
      return (<Dropdown text={`--- select ${param.label} ---`} items={items} valueField={valueField} displayField={displayField} onchange={value => setParam(value, param.itemType, index)} value={paramValue} />)
    }
    return null
  }
  return ParamValues({ action, param, method, value: paramValue, isDynamic, event, events, data, fetchData, setParam, paramValues, index, placeholder: param.label, validators: param.validators, required: !param.optional })
}


const ParamValues = ({ action, param, method, value, isDynamic, event, events, data, fetchData, setParam, index, paramValues, ...rest }) => {
  let valueField = "id", displayField = "label", orderField = "index";
  let loading = false;
  let paramLabel = param.type;
  let paramType = param.type;
  let valueType = method.data && method.data[param.type] && method.data[param.type].type || paramType;
  let typeValues = !!paramValues && Object.assign({}, paramValues[valueType]) || {};
  let onchange = (value, isDynamic) => setParam(value, paramType, index, isDynamic);
  if (method.data && method.data[param.type]) {
    let { source, kind, key, query, display, order } = method.data[param.type];
    const path = `${source}/${kind}` + (!!key ? `/${key}` : '') + (!!query ? `/${query}` : '');
    var actionData = data && data[path];
    Object.entries(typeValues).forEach(([k, val]) => {
      typeValues[k] = val.map((v, i) => ({
        id: v.id,
        label: v.label,
        index: i,
        isDynamic: v.isDynamic
      }))
    })

    if (!actionData) {
      fetchData(method.data[param.type]);
    } else if (actionData.loading) {
      loading = true;
    } else {
      paramLabel = kind;
      typeValues[kind] = data[path].data.map((d, i) => (
        typeof (d) == 'object' ? {
          id: d[key],
          label: display ? d[display] : d[key],
          index: order ? d[order] : i
        } : {
          id: d,
          label: d,
          index: i,
        }))
    }
    paramType = method.data[param.type].type || paramType;
  } else if (param.type == 'paramValue') {
    const propertyIdx = method.params.findIndex(p => (p.additional == param.id));
    if (propertyIdx != -1 && action.parameters[propertyIdx] && action.parameters[propertyIdx].value) {
      let capsuleIdx = method.params.findIndex(p => (p.additional == method.params[propertyIdx].id));
      if (capsuleIdx != -1 && capsuleIdx != index) {
        if (action.parameters[capsuleIdx] && action.parameters[capsuleIdx].value) {
          const capsulesData = data && data[`rules/capsule/component`] || null;
          if (!!capsulesData && !capsulesData.loading && !!capsulesData.data) {
            const capsuleData = capsulesData.data.find(c => c.component == action.parameters[capsuleIdx].value);
            if (capsuleData) {
              const propertyData = capsuleData.defaultProps.find(p => p.property == action.parameters[propertyIdx].value);
              if (propertyData) {
                paramType = propertyData.type;
                typeValues = !!paramValues && Object.assign({}, paramValues[paramType]) || {};
              }
            }
          }
        }
      } else if (event && (event.endsWith('_LOAD') || event.endsWith('_SUCCESS'))) {
        const ev = events.find(e => e.id == event.replace('_SUCCESS', '_LOAD'));
        if (ev) {
          const eventFieldData = ev.fields.find(p => p.id == action.parameters[propertyIdx].value);
          if (eventFieldData) {
            paramType = eventFieldData.type;
            typeValues = !!paramValues && Object.assign({}, paramValues[paramType]) || {};
          }
        }
      }
    }
  }
  if (['a', 'e', 'i', 'o', 'u'].indexOf(paramLabel.charAt(0)) > -1) {
    paramLabel = `an ${paramLabel}`
  } else {
    paramLabel = `a ${paramLabel}`
  }
  const TypeField = Fields[paramType];
  return ParamSelector({ TypeField, typeValues, isDynamic, value, onchange, paramLabel, valueField, displayField, orderField, loading, ...rest })
}
