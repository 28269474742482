export const tryParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    // console.error(e)
    return null;
  }
}

export const getParam = (param) => {
  var url = new URL(window.location);
  var params = new URLSearchParams(url.search);
  return params.get(param);
}

export const getFilterParam = () => {
  const filter = getParam('filter') || '';
  if (!filter) return {};
  return getFilterObj(filter);
}

export const getFilterObj = (filter) => {
  const filterObj = filter.split(';');
  var res = {};
  filterObj.forEach(f => {
    var obj = f.split(':');
    res[obj[0]] = obj[1];
  })
  return res;
}

export const getFilterPath = (key, value) => {
  const pathname = window.location.pathname;
  const search = window.location.search || "";
  const filterStr = getFilterStr(search, key, value)
  return pathname + (filterStr ? `?${filterStr}` : '');
}

export const getFilterStr = (search, key, value) => {
  const params = new URLSearchParams(search);
  const filterParams = params.get('filter') || '';
  var filters = [];
  if (filterParams) {
    filterParams.split(';').forEach(f => {
      var fp = f.split(':');
      if (fp[0] !== key) {
        filters.push(f);
      }
    })
  }
  if (key && value != undefined && value != '') {
    filters.push(`${key}:${value}`)
  }
  if (filters.length) {
    params.set('filter', filters.join(';'));
  } else {
    params.delete('filter');
  }
  const skip = params.get('skip') || '';
  if (skip) {
    params.set('skip', 0);
  }
  return params.toString()
}

export const toKeyValue = (item) => {
  return Object.keys(item).map((k, i) => ({ key: k, value: item[k], index: i }));
}

export const mapToObject = (a, ...ks) => {
  if (a && a.length && (typeof a[0] == "string")) {
    return a.map((i, x) => {
      const o = tryParse(i)
      var res = { index: x }
      ks.forEach(k => {
        res[k] = o && o[k] || i
      })
      return res
    })
  }
  return (a || []);
};

export const toHashCode = (s) => {
  let h = 0;
  for (let i = 0, l = s.length; i < l; i++) {
    h = 31 * h + s.charCodeAt(i);
    //h += (Math.pow(s.charCodeAt(i) * 31, l - i));
  }
  return h.toString(36).replace(/0+$/, '').replace(/-/g, '_');
}

export const generateID = () => {
  return ((new Date()).getTime() * Math.random() * 9e6).toString(36).slice(-5);
}

export const tagify = (t) => {
  return (t || "").replace(/\s*,\s*/g, ',').replace(/,{2,}/g, ',').replace(/^,/, '').replace(/,$/, '');
}

export const untagify = (s) => {
  return tagify(s).replace(/,/g, ', ');
}

export const validateEditor = (editor) => {
  if (editor) {
    const fields = editor.querySelectorAll('input[type="text"]');
    fields.forEach(i => {
      const ev = new Event('validate');
      i.dispatchEvent(ev);
    })
  }
}

export const validateButton = (button, error) => {
  if (error) {
    button.classList.add('disabled');
  } else {
    button.classList.remove('disabled');
  }
}

export const getUserAgent = () => {
  if (window && window.navigator) {
    const ua = window.navigator.userAgent;
    const isMobile = /mobile/i.test(ua);
    const isAndroid = /Android/.test(ua);
    const isIpad = /iPad/.test(ua);
    const isIphone = /iPhone/.test(ua);
    const isIpod = /iPod/.test(ua);
    const wm = /Windows Phone ([0-9\._]+)[\);]/.exec(ua);
    const wmVersion = wm && parseInt(wm[1]) || 0;
    const isNative = /nativeapp/i.test(ua);
    return {
      android: isAndroid,
      ipad: isIpad,
      iphone: isIphone,
      ipod: isIpod,
      wm: wm,
      wm7: wmVersion == 7,
      wm8: wmVersion == 8,
      desktop: !isAndroid && !isIpad && !isIphone && !isIpod && !wm,
      mobile: isMobile,
      native: isNative
    }
  }
  return {}
};

export const parseDate = (str) => {
  if (!str || str == '0001-01-01T00:00:00Z') {
    return "";
  }
  return new Date(str);
}

export const dateToLocaleString = (str) => {
  const date = parseDate(str);
  if (!date) return date;
  return date.toLocaleString();
}

export const dateToString = (str) => {
  const date = parseDate(str);
  if (!date) return date;
  return date.toISOString();
}

export const splitUnderscore = (e) => {
  return (e + "").split("_").map(s => {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }).join(' ');
}

export const propertyToString = (m) => {
  const l = (m + "").length;
  return (m + "").replace(/(^.|[A-Z])/g, (s, p, i, t) => {
    return (i == 0 ? s.toUpperCase() : (t[i - 1] == t[i - 1].toLowerCase() || (i < l - 1 && t[i + 1] == t[i + 1].toLowerCase()) ? ` ${s}` : s))
  });
}

export const capitalize = (s) => {
  if (!s) return s
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

export const pluralize = (s) => {
  if (!s) return s

  switch (s[s.length - 1]) {
    case 'y':
      return s.slice(0, -1) + 'ies';
    case 's':
      return s + 'es';
    default:
      return s + 's';
  }
}

export const dangerouslySetInnerHTML = html => element => {
  element.innerHTML = html
}

export const isFixed = (elm) => {
  var el;
  if (typeof elm === 'object') el = elm[0] || elm;
  else if (typeof elm === 'string') el = document.querySelector(elm);
  while (typeof el === 'object' && el.nodeName.toLowerCase() !== 'body') {
    if (window.getComputedStyle(el).getPropertyValue('position').toLowerCase() === 'fixed') return true;
    el = el.parentElement;
  }
  return false;
};

export const setPosition = (el) => {
  const fixed = isFixed(el);
  el.dataset.fixed = +fixed;
  const bounds = el.getBoundingClientRect();
  // el.style.position = "fixed";
  // el.style.top = bounds.top + "px";
  // el.style.left = bounds.left + "px";
  if (bounds.top + el.offsetHeight + window.scrollY > document.body.offsetHeight) {
    if (!fixed && bounds.top + el.parentElement.offsetHeight + el.offsetHeight <= document.body.offsetHeight) {
      el.style.top = bounds.top - el.parentElement.offsetHeight - el.offsetHeight + "px";
    } else if (bounds.top + el.offsetHeight + window.scrollY > document.body.offsetHeight) {
      el.style.maxHeight = document.scrollingElement.clientHeight - bounds.top + "px";
      el.style.overflowY = "scroll";
      el.style.overflow = "auto";
    }
  }
  if (bounds.left - el.offsetWidth + window.scrollX > document.body.offsetWidth) {
    if (!fixed && bounds.left - el.parentElement.offsetWidth - el.offsetWidth > 0) {
      el.style.left = bounds.left - el.parentElement.offsetWidth - el.offsetWidth + "px";
    } else if (bounds.left + el.offsetWidth + window.scrollx > document.body.offsetWidth) {
      el.style.maxWidth = document.scrollingElement.clientWidth - bounds.left + "px";
      el.style.overflowX = "scroll";
      el.style.overflow = "auto";
    }
  }
}

const addParamValue = (values, name, id, label, type) => {
  values[type] = values[type] || {};
  values[type][name] = values[type][name] || [];
  values[type][name].push({ id: name + (id ? ('.' + id) : ''), label, type, isDynamic: true });
  if (type != 'value') {
    values.value = values.value || {};
    values.value[name] = values.value[name] || [];
    values.value[name].push({ id: name + (id ? ('.' + id) : ''), label, type, isDynamic: true });
  }
}

export const getParamValues = (event, { events, transactions, criterias, datasources }) => {
  const values = {};
  if (event) {
    event.fields.forEach(f => {
      addParamValue(values, 'event', f.id, f.label, f.type);
    })
  }
  criterias.forEach(c => {
    if (c.aggregations) {
      c.aggregations.forEach(a => {
        addParamValue(values, (c.datasource.alias || c.datasource.key), a.alias, a.alias, a.type);
      })
    }
    if (c.datasource) {
      const ds = datasources.find(d => d.id == c.datasource.id);
      if (ds) {
        if (ds.props) {
          ds.props.forEach(p => {
            const id = c.datasource.alias || c.datasource.key;
            addParamValue(values, id, p.id, p.label, p.type);
          })
        } else if (ds.returns == "paramType" && ds.params && ds.params.length && !ds.methods) {
          if (c.parameters && c.parameters.length) {
            const idx = ds.params.findIndex(p => p.type == "eventType")
            if (idx > -1) {
              const pev = c.parameters[idx].value;
              const paramEvent = events.find(e => (e.id == pev));
              const id = c.datasource.alias || c.datasource.key;
              if (paramEvent) {
                paramEvent.fields.forEach(f => {
                  addParamValue(values, id, f.id, (f.label || f.id), f.type);
                })
              }
            } else {
              const tidx = ds.params.findIndex(p => p.type == "transactionType")
              if (tidx > -1) {
                const ptx = c.parameters[tidx].value;
                const paramTrans = transactions.find(e => (e.id == ptx));
                const tid = c.datasource.alias || c.datasource.key;
                if (paramTrans) {
                  paramTrans.fields.forEach(f => {
                    addParamValue(values, tid, f.id, (f.label || f.id), f.type);
                  })
                }
              }
            }
          }
        } else if (c.datasource.alias && ds.returns && (!ds.params || !ds.params.length || c.parameters.length == ds.params.length)) {
          addParamValue(values, c.datasource.alias, "", c.datasource.alias, ds.returns);
        }
      }
    }
    if (c.conditions) {
      c.conditions.forEach(co => {
        if (co.alias && datasources && co.field) {
          const ds = datasources.find(d => d.id == co.field);
          if (ds && ds.disabled) {
            addParamValue(values, co.alias, "", co.alias, co.type);
          }
        }
      })
    }
  })
  return values;
}

export const getLastCondition = (conditions) => {
  if (conditions.length) {
    var lastCondition = conditions[conditions.length - 1];
    if (lastCondition.items) {
      return getLastCondition(lastCondition.items) || lastCondition;
    }
  }
  return null
}

export const slugify = (str, opts) => {
  if (!str || typeof str !== 'string') {
    return '';
  }
  opts = {
    maxLength: 50, // Max slug length
    spaceChar: '-', // Space replacement
    invalidChar: '_', // Invalid char replacement
    fr: 'àáâãäåçèéêëìíîïñðóòôõöøùúûüýÿ', // Accent chars to find
    to: 'aaaaaaceeeeiiiinooooooouuuuyy' // Accent replacement
  }

  str = str.replace(/^\s+|\s+$/g, '').toLowerCase();

  // Convert all accent characters
  for (var i = 0; i < opts.fr.length; i++) {
    str = str.replace(new RegExp(opts.fr.charAt(i), 'g'), opts.to.charAt(i));
  }

  // Replace all invalid characters and spaces, truncating to the max length
  return str.replace(/[^a-z0-9 -]/g, opts.invalidChar).replace(new RegExp('[' + opts.invalidChar + ']' + '+', 'g'), opts.invalidChar).replace(/\s+/g, opts.spaceChar).substr(0, opts.maxLength);
}

export const sortItems = (items, orderField, displayField, valueField) => {
  return items.sort((a, b) => {
    const da = orderField && a[orderField] !== "" && typeof a[orderField] !== 'undefined' ? a[orderField] : displayField && a[displayField] !== "" && typeof a[displayField] !== 'undefined' ? a[displayField] : (valueField && typeof a[valueField] !== 'undefined' ? a[valueField] : a);
    const db = orderField && b[orderField] !== "" && typeof b[orderField] !== 'undefined' ? b[orderField] : displayField && b[displayField] !== "" && typeof b[displayField] !== 'undefined' ? b[displayField] : (valueField && typeof b[valueField] !== 'undefined' ? b[valueField] : b);
    return da > db ? 1 : -1;
  })
}